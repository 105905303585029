import React from 'react'



const Recepie = () => {
  return (
    <div style={{padding:'5rem'}}>
      Receipe 
  
    </div>
  )
}

export default Recepie
